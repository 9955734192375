html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {

  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
    scroll-behavior: smooth;
}

@font-face {
    font-family: 'Press Start 2P', cursive;
    src: local("VT323-Regular"), url(VT323-Regular.ttf) format("ttf");
}

@font-face {
    font-family: 'Press Start 2P', cursive;
    src: local("PressStart2P-Regular"), url(PressStart2P-Regular.ttf) format("ttf");
}

body {
  margin: 0;
  font-family: 'VT323', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

code {
    font-family: 'Press Start 2P', cursive;
}



div {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}



body {
    overflow-x: hidden;
    background-color: #175e96 !important;
    background-image: linear-gradient( #175e96 0%, #164f7a 100%)
}


/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #485563;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #29323c;
    border-radius: 12px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #1b242e;
    border-radius: 12px;
  }

.navbar,
body {
    width: 100vw;
    max-width: 100%
}

.navbar {
    position: absolute;
    z-index: 10000;
    top: 0;
    left: 0
}
.minter-navbar{
    margin-right: 0px;
}

.drawer {
    background: #000 !important;
    color: #fff !important;
    padding-top: 150px;
    width: 400px;
    text-align: center
}

.drawer-item {
    display: flex !important;
    justify-content: center !important
}

.drawer-item h3 {
    margin: 5px !important;
    letter-spacing: 1.5px;
    transition: letter-spacing .2s ease-in-out !important
}

.drawer-item h3 a {
    color: inherit;
    opacity: 1;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;

    text-decoration: inherit
}

.drawer-item h3 a:hover{
    opacity: .5;
    text-decoration: inherit
}


.logo-text {
    font-family: 'Press Start 2P', cursive;
    font-size: 30px;
    white-space: nowrap
}

.hero-img {
    position: absolute;
    width: 100%
}

.hero {
    top: 0;
    left: 0;
    height: 100vh;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover
}

.hero h1 {
    font-family: 'Press Start 2P', cursive;
    font-size: 150px;
    white-space: nowrap;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
    letter-spacing: 4px;
    color: #fff
}

@media (max-width:960px) {
    .hero h1 {
        top: 30% !important;
        font-size: 100px !important
    }

}

@media (max-width:450px) {
    .contract-btn {
        font-size: 10px !important
    }
    .countdown-item {
        padding: 15px !important
    }
    .countdown-value {
        font-size: 20px !important
    }
    .countdown-text {
        font-size: 10px !important
    }
    .hero h1 {
        top: 20% !important;
        font-size: 60px !important
    }
    h3 {
        font-size: 20px !important
    }
    #roadmap {
        margin-bottom: 100% !important
    }
    .drawer {
        width: 100vw !important
    }
}

@media (max-width:750px) {
    .countdown-container {
        margin-top: 10% !important;
        max-width: 90%;
        width: 90%;
        margin: auto;
        min-width: auto !important
    }
    #about {
        padding-top: 0 !important
    }
    h2 {
        line-height: 1.6;
        font-size: 25px !important
    }
    p {
        font-size: 25px !important
    }
    .discord-btn {
        font-size: 15px !important
    }
    .logo-text {
        display: none
    }
}

.jar {
    padding-top: 300px;
    z-index: 15
}
@media only Screen and (max-width: 40em) {
    .jar {
        padding-top: 150px;
    }
}

@keyframes float {
    0% {
        transform: translatex(0)
    }
    50% {
        transform: translatex(-15px)
    }
    to {
        transform: translatex(0)
    }
}

@keyframes float2 {
    0% {
        transform: translatex(0)
    }
    50% {
        transform: translatex(35px)
    }
    to {
        transform: translatex(0)
    }
}

@keyframes float3 {
    0% {
        transform: translatex(0)
    }
    50% {
        transform: translatex(-25px)
    }
    to {
        transform: translatex(0)
    }
}

@keyframes float4 {
    0% {
        transform: translatex(0)
    }
    50% {
        transform: translatex(15px)
    }
    to {
        transform: translatex(0)
    }
}

@keyframes float5 {
    0% {
        transform: translatex(0)
    }
    50% {
        transform: translatex(10px)
    }
    to {
        transform: translatex(0)
    }
}

.cloud1 {
    z-index: 10
}

.ufo2 {
    z-index: 9
}

.cloud3 {
    z-index: 8
}

.cloud4 {
    z-index: 7
}

.cloud5 {
    z-index: 6
}

.cloud6 {
    z-index: 5
}

.cloud7 {
    z-index: 4
}

.cloud8 {
    z-index: 3
}

.alienship {
    z-index: 11;
}
@media only Screen and (max-width: 40em) {
    .alienship {
        display: none;
    }
}

.bg-main {
    z-index: 10
}

.footer-box,
.footer-box p {
    text-align: center
}

.footer-socials {
    display: flex;
    justify-content: center
}

.footer-container {
    padding: 10% 0;
    margin: 0 !important;
    max-width: none !important;
    z-index: 20
}

.footer-socials img {
    margin: 10px;
    height: 45px;
    cursor: pointer;
    opacity: 1;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}


.footer-socials img:hover {
    opacity: .5;
}



.logo-text-footer {
    font-family: 'Press Start 2P', cursive;
    font-size: 40px;
    color: #fff
}

#about,
#faq,
#team {
    position: relative;
    z-index: 20
}

.accordionbg {
	box-shadow: 0 0 1rem 0 rgba(255, 255, 255, 0.2);	
	border-radius: 5px;
	position: relative;
	background: inherit;
	overflow: hidden;
}

.accordionbg:before {
	content: "";
	position: absolute;
	background: inherit;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	box-shadow: inset 0 0 2000px rgba(87, 86, 87, 0.5);
	filter: blur(10px);
	margin: -20px;
}

.accordion {
    background-color: transparent !important;
    color: #fff !important;
    margin-bottom: 25px;
}

.accordion-details {
    margin-top: 0 !important;
    color: rgb(219, 219, 219) !important;
}

.about-title {
    text-align: center
}

#about {
    padding-top: 150px
}

h2 {
    font-size: 35px;
    line-height: 1.6;
    font-family: 'Press Start 2P', cursive !important;
    letter-spacing: 4px !important;
    margin: 0 !important;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    z-index: 27;
    color: #fff
}
h3 {
    font-family: 'VT323', monospace !important;
    letter-spacing: 4px !important;
    margin: 0 !important;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    z-index: 27;
    color: #fff
}

.welcome-content {
    font-size: 18px;
    font-family: 'VT323', monospace;
    letter-spacing: 2px;
    text-align: center
}

.welcome-content p {
    font-size: 25px;
    text-align: center !important
}

.welcome-box {
    position: relative;
    z-index: 25
}

.container {
    margin: 10% 0;
    position: relative;
    z-index: 20
}

p {
    font-size: 25px;
    font-family: 'VT323', monospace;
    letter-spacing: 2px;
    text-align: left
}

h3,
p {
    color: #fff
}

h3 {
    font-size: 30px;
    font-family: 'VT323', monospace;
    letter-spacing: 4px;
    text-align: center;
    text-transform: uppercase
}

.accordion-details {
    text-align: center !important
}

.floating-pickle {
    position: absolute;
    z-index: 15
}

.float1 {
    transform: rotate(-20deg);
    left: 10%;
    opacity: .75;
    width: 250px;
    bottom: -30%
}

.float2 {
    transform: rotate(75deg);
    right: 5%;
    opacity: .8;
    width: 400px;
    bottom: -15%
}

.float3 {
    bottom: -250%
}

.float4 {
    bottom: -270%
}


.float5 {
    bottom: -300%
}

.float3,
.float4,
.float5 {
    opacity: .8;
    width: 1200px;
    z-index: 26
}



.MuiAccordionSummary-content {
    justify-content: center;
    margin-bottom: 0 !important
}


.message {
    position: absolute;
    top: 20%;
    left: 50px;
    min-width: 150px;
    max-width: 80%
}

.message h3 {
    text-align: left !important
}


.bottom {
    width: 100%;
    position: absolute;
    opacity: .9;
    left: 0;
}

@media (max-width:750px) {
    .float3,
    .float4 {
        left: -100%
    }
    .table-body,
    .table-cell,
    .table-row {
        display: block !important;
        text-align: left !important
    }
    .table-row {
        border-top: 1px solid #fff !important
    }
    .table-cell {
        border: none !important
    }
    .table-cell-desc {
        padding-top: 0 !important
    }
    .table-cell-desc p {
        margin-top: 0 !important
    }
    #about {
        padding-top: 0 !important
    }

}

.logo {
    width: 80px
}

.discord-btn,
.mint-button {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 4px !important;
    font-family: 'Press Start 2P', cursive !important;
    font-weight: 400 !important;
    font-style: normal;
    transition: letter-spacing .2s ease-in-out !important;
    color: #fff !important;
    background: #000 ;
    min-width: 125px !important;
    cursor: pointer;
    padding: 12px 16px;
    border-radius: 4px;
    z-index: 1000;
    white-space: nowrap
}



.discord-btn span,
.mint-button span {
    justify-content: center;
    font-size: 25px
}

@media (min-width:450px) {
    .discord-btn:hover,
    .mint-button:hover {
        letter-spacing: 6px !important;
        background: radial-gradient(circle, #a2f793, #58f03e);
        color: rgb(0, 0, 0) !important;
        -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5),
        0 0 20px 20px rgba(88, 240, 62, 0.4);
        box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5),
        0 0 20px 20px rgba(88, 240, 62, 0.4);
    }
}

@media (min-width:1450px) {
    #about {
        padding-top: 400px !important
    }
}

@media (min-width:750px) {
    .cloud1 {
        animation: float 10s ease-in-out infinite
    }
    .ufo2 {
        animation: float2 8s ease-in-out infinite
    }
    .cloud3 {
        animation: float3 9s ease-in-out infinite
    }
    .cloud4 {
        animation: float2 8s ease-in-out infinite
    }
    .cloud5 {
        animation: float5 8s ease-in-out infinite
    }
    .cloud6 {
        animation: float5 6s ease-in-out infinite
    }
    .cloud7 {
        animation: float5 7s ease-in-out infinite
    }
    .cloud8 {
        animation: float5 6s ease-in-out infinite
    }
}

.loader {
    width: 100vw;
    height: 100vh;
    display: flex !important;
    justify-content: center !important;
    margin: 0 !important;
    text-align: center !important;
    max-width: none !important;
    align-items: center !important;
    position: fixed;
    background-color: #000;
    z-index: 1000000001;
    top: 0
}

.logo-text-loader span {
    display: inline-block;
    font-family: 'Press Start 2P', cursive;;
    font-size: 50px;
    color: #fff;
    position: relative
}

.loader-text {
    padding-top: 10px
}

.team-img {
    border: 5px solid #fff;
    border-radius: 65px;
    width: 70%;
    height: auto
}
@media (max-width:960px) {
    .team-img {
        width: 90%;
    }
}

.team-desc,
.team-desc p {
    text-align: center;
    margin: 15px;
}

.team-desc p {
    font-size: 25px;
    margin: 0 !important
}

.team-desc h3 {
    font-size: 30px;
    margin-bottom: 0 !important
}


.team-item-container {
    text-align: center;
    margin: 20px 0 !important
}

.verify-button,
.verify-button span {
    font-size: 25px;
    text-transform: uppercase;
    letter-spacing: 4px !important;
    font-family: 'Press Start 2P', cursive !important;
    font-weight: 400 !important;
    font-style: normal;
    transition: letter-spacing .2s ease-in-out !important;
    color: #fff !important;
    min-width: 125px !important;
    cursor: pointer;
    padding: 12px 16px;
    border-radius: 4px;
    z-index: 1000;
    white-space: nowrap
}

.verify-button span {
    padding: 0 !important
}

.verify-button {
    background: #000 !important;
    justify-content: center !important
}

.verify-button:hover,
.verify-button span:hover {
    letter-spacing: 6px !important
}

.verify-container {
    display: flex !important;
    justify-content: center;
    height: 100vh
}

.alert .MuiAlert-icon,
.verify-container {
    align-items: center
}

.countdown-container {
    position: absolute;
    margin-top: -5%;
    left: 50%;
    transform: translate(-50%, -35%);
    text-align: center;
    z-index: 1000;
    min-width: 500px
}

.countdown-container h3 {
    color: rgb(255, 255, 255)
}

.coutdown-grid {
    text-align: center
}

.countdown-item {
    padding: 30px
}

.countdown-border-right {
    border-right: 1px solid hsla(0, 0%, 100%, .5)
}

.countdown-value {
    font-size: 35px
}

.countdown-text,
.countdown-value {
    margin: 0 !important;
    font-weight: 700;
    text-align: center
}

.countdown-text {
    font-size: 20px;
    color: #ffffff
}

.mintButton-container {
    position: absolute;
    z-index: 100000;
    left: 50%;
    align-content: center;
    transform: translateX(-50%);
    margin-top: 10px;
    max-width: 90%;
    background-color: #175e96;
    background-image: url("../assets/images/Pass.png");
    border-radius: 5%;
    padding: 5%;
    padding-left: 50px;
    padding-right: 50px;
}
@media only Screen and (max-width: 40em) {
    .mintButton-container {
        margin-top: 10px;
        padding: 2%;
        max-width: 90%;
        width: 90%;
        padding-top: 10%;
    }
}

.incr-decr {
    background: rgba(0, 0, 0, .4);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    max-width: 200px;
    margin: 10px auto
}

.incr-decr h3 {
    margin: 20px
}

.contract-btn {
    cursor: pointer
}

/*# sourceMappingURL=main.fc254505.chunk.css.map */
.pixel, .pixel2 {
    font-size: 25px;
    color: white;
    height: auto;
    margin: 10px;
    font-family: 'VT323';
    
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-transform: uppercase;
    
    cursor: pointer;
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  
  .pixel:active, .pixel2:active {
    top: 2px;
  }
  
  .pixel {
    line-height: 0;
    
    image-rendering: optimizeSpeed;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    
    border-style: solid;
    border-width: 20px;
    -moz-border-image: url(https://i.imgur.com/sREM8Yn.png) 20 stretch;
    -webkit-border-image: url(https://i.imgur.com/sREM8Yn.png) 20 stretch;
    -o-border-image: url(https://i.imgur.com/sREM8Yn.png) 20 stretch;
    border-image: url(https://i.imgur.com/sREM8Yn.png) 20 stretch;
  }
  
  .pixel p {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: auto;
    text-align: center;
    margin: -20px -20px;
    line-height: 20px;
    padding: 10px 20px;
    
      background: #000000;
      background:
          linear-gradient(135deg, transparent 10px, #000000 0) top left,
          linear-gradient(225deg, transparent 10px, #000000 0) top right,
          linear-gradient(315deg, transparent 10px, #000000 0) bottom right,
          linear-gradient(45deg,  transparent 10px, #000000 0) bottom left;
      background-size: 50% 50%;
      background-repeat: no-repeat;
      background-image:
          radial-gradient(circle at 0 0, rgba(204,0,0,0) 14px, #000000 15px),
          radial-gradient(circle at 100% 0, rgba(204,0,0,0) 14px, #000000 15px),
          radial-gradient(circle at 100% 100%, rgba(204,0,0,0) 14px, #000000 15px),
          radial-gradient(circle at 0 100%, rgba(204,0,0,0) 14px, #000000 15px);
  }
  
  .pixel2 {
    position: relative;
    display: block;
    margin: 50px;
    font-family: 'VT323';
    text-transform: uppercase;
    font-size: 25px;
    color: white;
  }
  
  .pixel2::before {
    content: "";
    display: block;
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: -10px;
    right: -10px;
    background: black;
    z-index: -1;
  }
  
  .pixel2::after {
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: -6px;
    right: -6px;
    background: black;
    z-index: -1;
  }
  
  .pixel2 {
    padding: 10px 10px;
    position: relative;
    background: black;
    width: auto;
    z-index: 2;
  }
  
.alien {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 4px !important;
    font-family: 'Press Start 2P', cursive !important;
    font-weight: 400 !important;
    font-style: normal;
    color: #fff !important;
    min-width: 125px !important;
    cursor: pointer;
    z-index: 1000;
    white-space: nowrap;
    
    position: relative;
    background-color: #252525;
    width: 150px;
    height: 150px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 100px;
    -webkit-box-shadow: 0 0 7px 7px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 7px 7px rgba(0, 0, 0, 0.5);
  }
  .alien:before {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: #0b0b0b;
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
    top: 100px;
    left: 50px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .alien:after {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: #0b0b0b;
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
    top: 50px;
    left: 100px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .alien:hover {
    background: radial-gradient(circle, #a2f793, #58f03e);
    color: #000 !important;
    -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5),
      0 0 20px 20px rgba(88, 240, 62, 0.4);
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.5),
      0 0 20px 20px rgba(88, 240, 62, 0.4);
  }

  .alientext {
    display: inline-block;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  /*--------------timeline-----------------*/
.journey-style{
    width: auto;
    max-width: 1000px;
    margin:0 auto; 

 }
  .journey-style .timeline {
      position: relative;
      padding: 21px 0px 10px;
      margin-top: 50px;
      margin-bottom: 30px;
 }
 
  .journey-style .timeline .line {
      position: absolute;
      width: 1px;
      display: block;
      background: currentColor;
      top: 0px;
      bottom: 0px;
      margin-left: 32px;
      color: rgba(240, 97, 143, 0.28);
 }
 
  .journey-style .timeline .separator {
      border-top: 1px solid currentColor;
      padding: 5px;
      padding-left: 40px;
      font-style: italic;
      font-size: .9em;
      margin-left: 30px;
 }
  .journey-style .timeline .line::before {
      top: -4px;
 }
  .journey-style .timeline .line::before{
      content: '';
      position: absolute;
      left: -6px;
      width: 13px;
      height: 13px;
      display: block;
      border-radius: 50%;
      background: #15b2ab;
 }
  .journey-style .timeline .panel {
      position: relative;
      margin: 10px 0px 21px 70px;
      clear: both;
 }
  .journey-style .timeline .panel::before {
      position: absolute;
      display: block;
      top: 8px;
      left: -24px;
      content: '';
      width: 0px;
      height: 0px;
      border: inherit;
      border-width: 12px;
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
 }
  .hover-color-link span:hover{
      color: #fff;
      background-image: linear-gradient(60deg, #29323c 0%, #485563 100%) !important;
      transition: 0.5s;
      box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
      transition: .2s ease-out;
 }
  .hover-color-link:hover{
      color: #fff !important;
 }
  .journey-style .timeline .panel .panel-heading.icon * {
      font-size: 20px;
      vertical-align: middle;
      line-height: 40px;
 }
  .journey-style .timeline .panel .panel-heading.icon {
      position: absolute;
      left: -60px;
      display: block;
      width: 42px;
      height: 42px;
      padding: 0px;
      border-radius: 50%;
      text-align: center;
      float: left;
      border-color: transparent;
      transition: 1.3s;
 }
  .journey-style .timeline .panel-outline {
      border-color: transparent;
      background: transparent;
      box-shadow: none;
 }
  .journey-style .journey-style .timeline .panel-outline .panel-body {
      padding: 10px 0px;
 }
  .journey-style .timeline .panel-outline .panel-heading:not(.icon), .journey-style .timeline .panel-outline .panel-footer {
      display: none;
 }
  .journey-style .panel-heading{
      background-color:#15b2ab;
      color:#ffffff;
 }
  .journey-style .date-extend .panel-heading{
      background-color:#f06292;
      color:#ffffff;
 }
  .journey-style .panel-body {
      padding: 0px;
 }
  .journey-style .panel-body h3{
      margin: 0px;
      text-align: left;
      margin-left: 1em;
      margin-top: -0.3em;
      padding-bottom: 0.5em;
      line-height: 1.3em;
 }
  .journey-style .panel-body h3 time{
      color: #ff6e41;

 }
  .journey-style .panel-body h3 p{
      color: #fff;
      font-weight: 400;
 }

  .journey-style .panel-outline:hover .panel-heading.icon{
      -webkit-transform: scale(1.7,1.7) rotate(45deg);
      -moz-transform: scale(1.7,1.7) rotate(45deg);
      -ms-transform: scale(1.7,1.7) rotate(45deg);
      -o-transform: scale(1.7,1.7) rotate(45deg);
      transform: scale(1.7,1.7) rotate(45deg);
      transition: background 0.5s;
      transition: 0.5s;
 }
  .journey-style .timeline .year-time{
      position: relative;
      left: -200px;
      color: #fff;
      top: 33px;
      font-size: 25px;
      float: left;
 }
  .journey-style .timeline .year-time1{
      position: relative;
      left: -200px;
      color: #fff;
      top: 33px;
      font-size: 25px;
      float: left;

 }

 .alice-carousel ul li img{
     
    height: 500px;
    width: 100%;
    object-fit: cover;
  }

  @media only Screen and (max-width: 40em) {
    .alice-carousel ul li img{
        height: 200px;
      }
    
}